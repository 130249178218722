header {
    height: 100vh;
    /* padding-top: 7rem; */
    overflow: hidden;
}


.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}
.header__identity{
    display: flex;
    justify-content:center;
}
.header__logo{
    display: flex;
    padding:0 1rem;
}
.logo{
    width: 4rem;
    margin:auto;
}
.header__text{
    text-align: left;
}
.header__text h1{
    line-height: 1.2em;
}

/* ========== CTA ========== */
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* ========== HEADERS SOCIALS ========== */
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header__socials::after {
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* ========== ME ========== */
.me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 5rem 5rem;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
}

/* ========== SCROLL DOWN ========== */
.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 200;
    font-size: 0.9rem;
}

/* ========== MEDIA QUERIES (MEDIUM) ========== */
@media screen and (max-width: 1024px) {
    header {
        height: 100vh;
    }
}

/* ========== MEDIA QUERIES (SMALL) ========== */
@media screen and (max-width: 600px) {
    header {
        height: 100vh;
    }

    .header_socials,
    .scroll_down {
        display: none;
    }
    .me {
        margin-top: 0;
    }
}