.resumes__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
}

.resume {
    background: var(--color-bg-variant);
    border-radius: 2rem;
    border: 1px solid var(--color-primary);
    height: fit-content;
    transition: var(--transition);
    position: relative;
    margin-top: 1px;
}

.resume:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.resume__head {
    background: var(--color-primary);
    padding: 2rem;
    border-radius: 2rem;
    box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.1);
    margin-top: -1px;
}

.resume__head h3 {
    color: var(--color-bg);
    font-size: 1rem;
    text-align: center;
}

.resume-item {
    @apply pl-5 pb-5 relative border-l-2 ;
    border-color: var(--color-primary);

    &:before {
        @apply absolute rounded-full top-0 border-2;
        background:var(--color-primary);
        border: var(--color-primary);
        width: 16px;
        height: 16px;
        left: -9px;
        content: "";
    }

    h4 {
        @apply leading-5 uppercase text-white;
    }

    p {
        @apply mb-0;
    }

    h5 {
        @apply text-base leading-4 text-gray-50;
    }

}

.resume__list {
    padding: 2rem;
}

.resume__list li {
    display: flex;
    gap: 1rem;
    margin-bottom: 0.8rem;
}

.resume__list-icon {
    color: var(--color-primary);
    margin-top: 2px;
}

.resume__list p {
    font-size: 0.9rem;
}

/* ========== MEDIA QUERIES (MEDIUM) ========== */
@media screen and (max-width: 1024px) {
    .resumes__container {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }

    .resume {
        height: auto;
    }
}

/* ========== MEDIA QUERIES (SMALL) ========== */
@media screen and (max-width: 600px) {
    .resumes__container {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }
}